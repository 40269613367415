import { Injectable, TemplateRef } from '@angular/core';
import { NgbToastOptions } from '@ng-bootstrap/ng-bootstrap/toast/toast-config';
import { BehaviorSubject } from 'rxjs';

export interface Toast extends NgbToastOptions {
  id: number;
  classname?: string;
  header?: string;
  textOrTemplate: string | TemplateRef<any>;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  static idCounter = 0;
  static readonly DEFAULT_TOAST_OPTIONS: Partial<Toast> = {
    ariaLive: 'polite',
    autohide: true,
    delay: 10000,
  };
  toasts$ = new BehaviorSubject<Toast[]>([]);
  private toasts: Toast[] = [];

  show(textOrTemplate: Toast['textOrTemplate'], options: Partial<Toast> = {}) {
    const id = ToastService.idCounter++;
    const toast: Toast = { ...ToastService.DEFAULT_TOAST_OPTIONS, ...options, id, textOrTemplate };
    this.toasts.push(toast);
    this.toasts$.next(this.toasts);
  }

  showWarning(textOrTemplate: Toast['textOrTemplate'], options: Partial<Toast> = {}) {
    options.classname = `toast--warning ${options.classname}`;
    options.ariaLive = 'alert';
    this.show(textOrTemplate, options);
  }

  showError(textOrTemplate: Toast['textOrTemplate'], options: Partial<Toast> = {}) {
    options.classname = `toast--danger ${options.classname}`;
    options.ariaLive = 'alert';
    this.show(textOrTemplate, options);
  }

  remove(id: number) {
    this.toasts = this.toasts.filter(item => item.id !== id);
    this.toasts$.next(this.toasts);
  }
}
